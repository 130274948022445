import React from 'react';
import { Field, reduxForm, getFormSyncErrors } from 'redux-form';
import {
    validate,
    validatorFromFunction,
    validators,
    combine,
} from 'validate-redux-form';

import {
    renderField,
    renderNumber,
    renderFieldCheck,
} from '../../../Utils/renderField/renderField';

import { Link } from "react-router-dom";
import { validationsOthers } from '../../../../../utility/validationForm'
import { ButtonSalirGuardar } from '../../../Utils/Button'
import { PERMISOS } from '../../../../../utility/constants'



const renderFieldError = ({ meta: { submitFailed, error } }) => (
    <React.Fragment>
        {submitFailed && <div className="invalid-feedback d-inline">{error}</div>}
    </React.Fragment>
)

let RolForm = (props) => {
    const { handleSubmit } = props;
    return (
        <form onSubmit={handleSubmit}>
            <div className="row card-form">

                <div className="col-md-6 padding-custom mb-3 align-self-end">
                    <label htmlFor="name">Nombre*</label>
                    <Field name="name" component={renderField} type="text" className="form-control" maxLength={validationsOthers.numeroLetro50.MaxLength} />
                </div>
                <div className="col-12 padding-custom mb-3 align-self-end">
                    <span className="negro d-block mb-2">Permisos*</span>
                    <Field name="permisosError" component={renderFieldError} />
                    <fieldset>
                        {PERMISOS.map((permiso) => (
                            <Field
                                name={permiso.id}
                                label={permiso.name}
                                component={renderFieldCheck}
                            />
                        ))}
                    </fieldset>
                </div>
                <div className="col-12 padding-custom mb-3">
                    <p className="azul text-12 bold">* Campos requeridos</p>
                </div>
            </div>
            <ButtonSalirGuardar link="/roles" />
        </form>
    );
};

export default reduxForm({
    form: 'RolForm',
    validate: (data) => {
        let errors = {};
        errors.permisosError = "Selecciona por lo menos un permiso."
        if (data) {
            for (const key in PERMISOS) {
                const clave = PERMISOS[key].id
                if (data[clave] === true) {
                    errors = {};
                    break;
                }
            }
        }
        return {
            ...errors, ...validate(data, {
                name: validationsOthers.numeroLetro50.Combine,
            })
        }
    },
})(RolForm);
