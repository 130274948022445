import React, { Component } from "react";
import { Field, reduxForm } from "redux-form";
import { Link } from "react-router-dom";
// import { renderFilePicker2 } from "../../../Utils/renderField";
import { renderFilePicker2 } from '../../../Utils/renderField/renderField'
import { validate, validators } from "validate-redux-form";

class ExcelForm extends Component {
    render() {
        const { handleSubmit, create , cerrarModal} = this.props;
        return (
            <React.Fragment>
                <form
                    name="excelForm"
                    className="form-validate mb-lg "
                    onSubmit={handleSubmit}
                >
                    <div className="d-flex justify-content-center flex-column flex-md-row pb-2">
                        <div className="d-flex flex-row align-items-center">
                            <label
                                className="font-weight-bold mr-5"
                                htmlFor="fileExcel"
                            >
                                Haz click en el botón o arrastra el archivo para cargar
                            </label>
                            <Field
                                name="fileExcel"
                                label="fileExcel"
                                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                excel={true}
                                component={renderFilePicker2}
                            />
                        </div>
                    </div>
                    <div className="mb-4 col-12 p-0">
                        <div className="row mb-5">
                            <div className="col-12 row">
                                <div className="col-12 d-flex justify-content-center mt-5">
                                    <div className="col-9 buttons-box">
                                        <button type="button" className="btn btn-outline-dark mr-5" onClick={cerrarModal}>CERRAR</button>
                                        <button type="submit" className="btn btn-outline-primary">{" "} {create ? "CARGAR" : "ACTUALIZAR"}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </React.Fragment>
        );
    }
}

export default reduxForm({
    form: "excelForm", 
    //validate: validate
    validate: (data) => {
        return validate(data, {
            fileExcel: validators.exists()("Este campo es requerido"),
        });
    },
})(ExcelForm);