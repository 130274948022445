export const USER_MANAGEMENT = 'user_management'
export const SUBSCRIPTION_MANAGEMENT = 'subscription_management'
export const MANAGEMENT_SHOPS_BRANCHES = 'management_shops_branches'
export const REPORTS = 'reports'
export const DELETE_REGISTERS = 'delete_registers'
export const ROLE_MANAGEMENT = 'role_management'
export const PAYMENT_MANAGEMENT = 'payment_management'

export const PERMISOS = [
    {
        'id': USER_MANAGEMENT,
        'name': 'Gestión de usuarios',
    },
    {
        'id': SUBSCRIPTION_MANAGEMENT,
        'name': 'Gestión de planes de suscripción',
    },
    {
        'id': MANAGEMENT_SHOPS_BRANCHES,
        'name': 'Gestión de talleres y sucursales',
    },
    {
        'id': PAYMENT_MANAGEMENT,
        'name': 'Gestión de pagos',
    },
    {
        'id': ROLE_MANAGEMENT,
        'name': 'Gestión de roles',
    },
    {
        'id': REPORTS,
        'name': 'Reportes',
    },
    {
        'id': DELETE_REGISTERS,
        'name': 'Eliminación de registros',
    },
]

//?  Estados de un taller
export const ACTIVE_GARAGE = 10
export const INACTIVE_GARAGE = 20
export const PENDING_GARAGE = 30

//? Tipo de pago
export const MONTHLY_PAYMENT = 11
export const ANNUAL_PAYMENT = 21

//SwalAlert
import Swal from 'sweetalert2';
export const SWALMODERROR = Swal.mixin({
    customClass: {
        confirmButton: 'btn btn-primary m-2 btn-salir',
        title: 'text-40 negro',
        image: 'blink-danger',
    },
    buttonsStyling: false,
    focusConfirm: false,
    focusCancel: false,
})

// Cambiar estilo para los Swal,
export const SWALMOD = Swal.mixin({
    customClass: {
        confirmButton: 'btn btn-primary m-2 btn-salir',
        cancelButton: 'btn btn-gris m-2 btn-salir',
        title: 'bold negro',
        image: 'blink-danger',
        validationMessage: 'my-validation-message',
    },
    preConfirm: (value) => {
        if (!value) {
            SWALMOD.showValidationMessage(
                'La justificación es importante.'
            )
        }
    },
    buttonsStyling: false,
    focusConfirm: false,
    focusCancel: false,
})
