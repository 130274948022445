import React, { Component, Fragment } from 'react'
import Grid from "../../../Utils/Grid";
import { TableHeaderColumn } from "react-bootstrap-table";
import { standardActions } from "../../../Utils/Grid/StandardActions";
import defaultLogo from 'assets/img/place_holder/image_placeholder_small.png'
import { api } from "api";
export default class Listar extends Component {
    componentWillMount() {
        const { listar, page } = this.props;
        api.get('garage/put_activate_or_inactivate').then(() => {
            listar(page);
        }
        )
        .catch((error) => {
            console.log(error)
        }
        )
    }

    render() {
        const { onSortChange, eliminar, listar } = this.props; //Funciones
        const { data, loader, page, deactivate_branch = false } = this.props; //Constantes
        return (
            <Fragment>

                <Grid hover striped data={data} loading={loader} onPageChange={listar} onSortChange={onSortChange} page={page}>
                    <TableHeaderColumn
                        dataField="id"
                        dataAlign="center"
                        isKey
                        width='150PX'
                        dataFormat={standardActions({ editarTaller: true, eliminar, verSucursal: !deactivate_branch, verPago: true })}
                    />
                    <TableHeaderColumn
                        dataField="logo"
                        tdStyle={{ filter: 'none' }}
                        width='80px'
                        dataFormat={(cell, row) => <img src={cell || defaultLogo} height="30" />}
                    >
                        LOGO
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="name"
                        dataSort
                    >
                        TALLER
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="email"
                        dataSort
                    >
                        E-MAIL
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="phone_number"
                        dataSort
                    >
                        TELÉFONO
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="proprietor"
                        dataFormat={(cell) => cell ? `${cell.first_name} ${cell.last_name}` : ""}
                    >
                        ADMINISTRADOR
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="plan"
                        dataFormat={(cell) => cell ? cell.name : ""}
                    >
                        PLAN
                </TableHeaderColumn>
                </Grid>
            </Fragment>
        )
    }
}
