import { connect } from 'react-redux';
import { actions } from '../../../../../redux/modules/pagos';
import { actions as actions_taller } from '../../../../../redux/modules/talleres';
import Listar from './Listar';


const ms2p = (state) => {
    return {
        ...state.pagos,
        datos_taller: { ...state.talleres },
    };
};

const md2p = {
    ...actions,
    leer_taller: actions_taller.leer
};

export default connect(ms2p, md2p)(Listar);
