import { connect } from 'react-redux';
import { actions } from '../../../../../redux/modules/planes';
import Crear from './Crear';


const ms2p = (state) => {
    return {
        ...state.planes,
    };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(Crear);
