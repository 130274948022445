import React, { Fragment } from 'react'
import { ButtonSalirGuardar } from '../../../Utils/Button';


const DatosFacturacion = ({ receipt_info = {} }) => {

    const {
        name = '',
        phone_number = '',
        city = '',
        address = '',
        country_text = '',
        email = '',
        tax_id = '',
    } = receipt_info || {}
    return (
        <Fragment>
            <div className='card-content-form2 px-0 px-0'>
                <div className="col-12 col-lg-9">
                    <div className="row card-form">
                        <div className="col-md-6 padding-custom mb-3">
                            <span className="d-block">Nombre</span>
                            <span className="text-18 bold negro d-block">{name}</span>
                        </div>
                        <div className="col-md-6 padding-custom mb-3">
                            <span className="d-block">ID tributario</span>
                            <span className="text-18 bold negro d-block">{tax_id}</span>
                        </div>
                        <div className="col-md-6 padding-custom mb-3">
                            <span className="d-block">Teléfono</span>
                            <span className="text-18 bold negro d-block">{phone_number || "--"}</span>
                        </div>
                        <div className="col-md-6 padding-custom mb-3">
                            <span className="d-block">E-mail</span>
                            <span className="text-18 bold negro d-block">{email || "--"}</span>
                        </div>
                        <div className="col-md-6 padding-custom mb-3">
                            <span className="d-block">País</span>
                            <span className="text-18 bold negro d-block">{country_text}</span>
                        </div>
                        <div className="col-md-6 padding-custom mb-3">
                            <span className="d-block">Ciudad</span>
                            <span className="text-18 bold negro d-block">{city}</span>
                        </div>
                        <div className="col-md-6 padding-custom mb-3">
                            <span className="d-block">Dirección</span>
                            <span className="text-18 bold negro d-block">{address}</span>
                        </div>
                    </div>
                </div>
            </div>
            <ButtonSalirGuardar formSubmit="TallerForm" link="/garages" />
        </Fragment>
    );
}
export default DatosFacturacion
