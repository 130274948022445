import React,  {Component} from "react";
import './FileUploaderExcel.css';
import { SWALMODERROR } from '../../../../utility/constants';
const alerta_img = require('assets/img/icons/warning.svg');

const defaultProps = {
    baseColor: 'gray',
    activeColor: 'green',
    overlayColor: 'rgba(255,255,255,0.3)',
    opacity:0
};

class FileUploaderExcel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            active: false,
            imageSrc: '',
            loaded: false
        };

        this.onDragEnter  = this.onDragEnter.bind(this);
        this.onDragLeave  = this.onDragLeave.bind(this);
        this.onDrop       = this.onDrop.bind(this);
        this.onFileChange = this.onFileChange.bind(this);
    }
    componentDidUpdate() {
        if (this.state.imageSrc === '' && this.props.source !== undefined && this.props.source !== null) {
            this.setState({imageSrc: this.props.source, loaded: true})
        }
    }

    componentWillMount(){
        if (this.props.img !== null && this.props.img !== undefined){
            // setea la imágen si se le envia una
            this.setState({
                imageSrc: this.props.img,
                loaded: true,
                isImage: true,
            });
        }
    }
    componentWillReceiveProps(nextProps){
        if (nextProps.img !== null && nextProps.img !== undefined){
            // setea la imágen si se le envia una
            let temp = true;
            this.props.excel ? temp=false : temp;
            this.setState({
                imageSrc: nextProps.img,
                loaded: temp,
                isImage: true
            });
        }
    }

    onDragEnter(e) {
        this.setState({ active: true });
    }

    onDragLeave(e) {
        this.setState({ active: false });
    }

    onDragOver(e) {
        e.preventDefault();
    }

    onDrop(e) {
        e.preventDefault();
        this.setState({ active: false });
        this.onFileChange(e, e.dataTransfer.files[0]);
    }

    isValid = (file_type) => {
        //console.log("file type: ", file_type);
        const {accept="-*"} = this.props;
        const pattern = accept == "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ? accept : (accept !== "-*" ? accept.replace('/','-') : accept);
        const accept_type = pattern.split(",")
        let aprobado = false;        
        accept_type.forEach(t=>{            
            const regex = new RegExp(t);            
           // console.log("regex: ", regex);            
            if(!!file_type.match(regex)){                
                aprobado = true;         
            }
        });        
        return aprobado;
    }

    onFileChange(e, file) {
        const { accept = '/*' } = this.props;
        this.props.onFileChange(e, file);
        file = file || e.target.files[0];
        // const pattern = /-*/;
        const pattern = `${accept}`;
        const imagePattern = /image-*/;

        const reader = new FileReader();
        if (file){            
            //console.log('Tipo:', file.type);
            const isImage = !!file.type.match(imagePattern);
            /* if (!file.type.match(pattern)) { */
            if (!this.isValid(file.type)) {
                SWALMODERROR.fire({
                    title: '¡Información!',
                    text: '¡Selecciona un archivo de tipo EXCEL!',
                    confirmButtonText: 'Regresar',
                    reverseButtons: true,
                    imageUrl: alerta_img,
                    imageAlt: 'Alerta',
                    imageWidth: 90,
                })             
                return;
            }
            
            this.setState({ loaded: false });

            reader.onload = (e) => {
                this.setState({
                    imageSrc: reader.result,
                    isImage,
                    loaded: true
                });
            };
            reader.readAsDataURL(file);
        }
    }

    getFileObject() {
        return this.refs.input.files[0];
    }

    getFileString() {
        return this.state.imageSrc;
    }

    render() {
        const { accept = '/*' } = this.props;
        let state = this.state,
            props = defaultProps,
            labelClass  = `uploader ${state.loaded && 'loaded'}`,
            borderColor = state.active ? props.activeColor : props.baseColor,
            iconColor   = state.active
                ? props.activeColor
                : (state.loaded)
                    ? props.overlayColor
                    : props.baseColor,
            hideIcon = state.loaded ? 0 : 1;

        return (
            <label
                className={labelClass}
                onDragEnter={this.onDragEnter}
                onDragLeave={this.onDragLeave}
                onDragOver={this.onDragOver}
                onDrop={this.onDrop}
                style={{outlineColor: borderColor}}>

                <img src={state.isImage ? state.imageSrc :(this.props.excel == true ? require('assets/img/excel-uploaded.png') : require('assets/img/uploaded.png') ) } className={state.loaded ? 'loaded' : (this.props.excel ? 'loaded-excel' : undefined)}/>
                <img style={{ color: iconColor, opacity: hideIcon }} className="icon icon-upload pt-4"
                     src={this.props.excel == true ? require(`assets/img/excel.png`) : require(`assets/img/upload2.png`)} alt=""/>
                {/* <p className="texto gris text-center" style={{opacity:hideIcon}}>Subir Archivo</p> */}
                <input disabled={this.props.disabled} type="file" accept={accept} onChange={this.onFileChange} ref="input" />

            </label>
        );
    }
}
export default FileUploaderExcel
