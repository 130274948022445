import { validators, combine, optional, validatorFromFunction } from 'validate-redux-form';
const noSpace = validatorFromFunction(value => value && value.trim())


const numeroLetro50 = {
    Combine: combine(
        noSpace()('Campo requerido.'),
        validators.exists()('Campo requerido.'),
        validators.regex(/^[a-zA-ZÀ-ž0-9\s]+$/)('Sólo se permiten letras y números'),
        validators.length({ max: 50 })('Máximo 50 carácteres')
    ),
    MaxLength: 51,
}


const numeroLetro150 = {
    Combine: combine(
        noSpace()('Campo requerido.'),
        validators.exists()('Campo requerido.'),
        validators.regex(/^[a-zA-ZÀ-ž0-9\s]+$/)('Sólo se permiten letras y números'),
        validators.length({ max: 150 })('Máximo 150 carácteres')
    ),
    MaxLength: 151,
}


const caracteres250 = {
    Combine: combine(
        noSpace()('Campo requerido.'),
        validators.exists()('Campo requerido.'),
        validators.length({ max: 250 })('Máximo 250 carácteres')
    ),
    MaxLength: 251,
}

const requerido = {
    Combine: combine(
        validators.exists()('Campo requerido.'),
    ),
    MaxLength: undefined,
}

const username = {
    Combine: combine(
        validators.exists()('Campo requerido.'),
        validators.regex(/^\S+$/)('No puede contener espacios'),
        validators.length({ min: 3 })('Mínimo 3 carácteres'),
        validators.length({ max: 100 })('Máximo 100 carácteres'),
    ),
    MaxLength: 101,
}

const name = {
    Combine: combine(
        noSpace()('Campo requerido.'),
        validators.exists()('Campo requerido.'),
        validators.regex(/^[a-zA-ZÀ-ž\s]+$/)('Sólo se permiten letras'),
        validators.length({ min: 3 })('Mínimo 3 carácteres'),
        validators.length({ max: 250 })('Máximo 250 carácteres'),
    ),
    MaxLength: 251,
}



const email = {
    Combine: combine(
        validators.exists()('Campo requerido.'),
        validators.regex(/^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@+[a-zA-Z0-9-]{1,}\.{1}[a-zA-Z0-9-]{2,}$/)('El correo electrónico no es valido'),
        validators.length({ max: 100 })('Máximo 100 carácteres'),
    ),
    MaxLength: 101,
}
const optional_email = {
    Combine: (e) => (e ? optional(email.Combine)(e) : null),
    MaxLength: email.MaxLength,
}


const phone_number = {
    Combine: combine(
        validators.exists()('Campo requerido.'),
        validators.regex(/^[+]?([0-9]+)$/)('Número de teléfono no válido'),
        validators.length({ min: 8 })('Mínimo 8 dígitos'),
        validators.length({ max: 15 })('Máximo 15 dígitos'),
    ),
    MaxLength: 16,
}

const prefix = {
    Combine: combine(
        validators.exists()('Campo requerido.'),
        validators.regex(/^[a-zA-ZÀ-ž\s]+$/)('Sólo se permiten letras'),
        validators.length({ max: 3, min: 3 })('Ingrese 3 letras.')
    ),
    MaxLength: 3,
}

const subdomain = {
    Combine: combine(
        validators.exists()('Campo requerido.'),
        validators.regex(/^[a-zA-ZÀ-ž\s]+$/)('Sólo se permiten letras'),
        validators.length({ max: 30 })('Máximo 30 letras'),
        validators.length({ min: 3 })('Mínimo 3 letras'),
    ),
    MaxLength: 31,
}

const optional_phone_number = {
    Combine: (e) => (e ? optional(phone_number.Combine)(e) : null),
    MaxLength: phone_number.MaxLength,
}

export const validationEmail = {
    email,
}

export const validationAdministrador = {
    first_name: name,
    last_name: name,
    username,
    email,
    phone_number,
    role: requerido,
}

export const validationsOthers = {
    requerido,
    numeroLetro50,
}

export const validationPlanes = {
    name: caracteres250,
    monthly_fee: requerido,
    annual_fee: requerido,
    work_orders: requerido,
    enable_type_work_orders: requerido,
}

export const validationGarage = {
    name: caracteres250,
    phone_number,
    email,
    country: requerido,
    city: caracteres250,
    address: caracteres250,
    subdomain,
    prefix: prefix,
}

export const validationBranchGarage = {
    name: caracteres250,
    phone_number,
    email,
    country: requerido,
    city: caracteres250,
    address: caracteres250,
}

export const validationReceiptInfo = {
    name: caracteres250,
    country: requerido,
    city: caracteres250,
    address: caracteres250,
    tax_id: caracteres250,
    phone_number: optional_phone_number,
    email: optional_email,
}

export const validationPayment = {
    reference: caracteres250,
    amount: requerido,
    payment_date: requerido,
}

export const validationModel = {
    name: caracteres250,
}

export const validationBrand = {
    name: caracteres250,
}
