import React, { Fragment } from 'react';
import { Field, reduxForm } from 'redux-form';
import { validate, validators, combine } from 'validate-redux-form';
import { renderField } from 'Utils/renderField';


const ChangePasswordForm = (props) => {
    const { handleSubmit, recover } = props;
    return (

        <form
            name="loginForm"
            className="form-validate mb-lg text-center"
            onSubmit={handleSubmit}
        >
            <h6 className="text-bold mb-4 mt-n3">
                {!recover ?
                    "PRIMER INGRESO."
                    :
                    "RECUPERACIÓN DE CONTRASEÑA"
                }
            </h6>

            <div className="form-group has-feedback">
                <label className="bold" htmlFor="password">Ingresa nueva contraseña</label>
                <Field
                    name="password"
                    label="password"
                    component={renderField}
                    type="password"
                    className="form-control"
                    maxLength="151"
                />
            </div>
            <div className="form-group has-feedback">
                <label className="bold" htmlFor="password_confirmation">Confirmar contraseña</label>
                <Field
                    name="password_confirmation"
                    label="password_confirmation"
                    component={renderField}
                    type="password"
                    className="form-control"
                    maxLength="151"
                />
            </div>
            <div className="buttons-box mt-4">
                <button
                    type="submit"
                    className="btn btn-primary text-18 btn-block m-1 align-self-center"
                >
                    REGISTRAR
                </button>
            </div>
        </form>
    );
};

export default reduxForm({
    form: 'ChangePasswordForm',
    validate: (data) => {
        return validate(data, {
            password: combine(
                validators.exists()('Campo requerido.'),
                validators.length({ min: 6 })('Mínimo 6 carácteres'),
                validators.length({ max: 150 })('Máximo 150 carácteres'),
            ),
            password_confirmation: combine(
                validators.exists()('Campo requerido.'),
                validators.equals(data.password)('La contraseña no coincide')
            ),
        });
    },
})(ChangePasswordForm);
