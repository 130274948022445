import React, { Fragment } from 'react';
import defaultLogo from 'assets/img/place_holder/image_placeholder.png'

function CardSearch(props) {
    const { subTitulo } = props
    return (
        <Fragment>
            <div className="mb-1 mb-md-2">
                <h1 className="text-45 medium d-inline my-0">{props.titulo} </h1>
                {subTitulo &&
                    <span className="text-20 medium my-0">{subTitulo}</span>
                }
            </div>
            {props.header}
            <div className="row pl-3 pb-4">
                <div className="d-flex justify-content-end align-items-start">
                    <img
                        className="main-logo-sidebar mr-3"
                        src={props.logo || defaultLogo}
                        alt="Logo" />
                    <div className="d-flex justify-content-end align-items-end">
                        <span className="text-25 medium">{props.nombre_sucursal}</span>
                    </div>

                </div>
            </div>
            <div className="card-small p-2 p-md-4 pb-4 card-empty">
                <div className='px-0 px-0'>
                    {props.children}
                </div>
            </div>
        </Fragment>
    )
}

export default CardSearch;
