import React, { Component } from 'react'
import Grid from "../../../Utils/Grid";
import { TableHeaderColumn } from "react-bootstrap-table";
import { standardActions } from "../../../Utils/Grid/StandardActions";
import CardLogo from '../../../Utils/Cards/CardLogo'
import { HeaderBtnSearch } from '../../../Utils/Header'



export default class Listar extends Component {
    componentWillMount() {
        const { listar, leer_taller, datos_taller } = this.props;
        const { idGarage = 0 } = this.props.match.params
        listar(1, idGarage);
        if (Object.keys(datos_taller.item).length === 0 || datos_taller.item.id != idGarage) {
            leer_taller(idGarage)
        }
    }

    render() {
        const { listar, onSortChange, eliminar, searchChange, aprobar_sucursal } = this.props; //Funciones
        const { data, loader, page, datos_taller } = this.props; //Constantes
        const idGarage = this.props.match.params.idGarage
        const rutaEditar = `/garage/${idGarage}/branch`
        return (
            <CardLogo
                titulo="Sucursales"
                subTitulo="Taller"
                logo={datos_taller.item.logo}
                nombre_sucursal={datos_taller.item.name}
                header={
                    <HeaderBtnSearch
                        link={`${rutaEditar}/create`}
                        textBtn="Crear sucursal"
                        textBtn2="Salir"
                        link2="/garages"
                        {...this.props}
                        searchChange={(e) => {
                            searchChange(e, idGarage)
                        }}
                    />
                }
            >
                <Grid hover striped data={data} loading={loader}
                    onPageChange={(e) => {
                        listar(e, idGarage)
                    }}
                    onSortChange={(e) => {
                        onSortChange(e, idGarage)
                    }}
                    page={page}>
                    <TableHeaderColumn
                        dataField="id"
                        dataAlign="center"
                        isKey
                        width='15%'
                        dataFormat={(cell, row) => standardActions(
                            row.approved ? {
                                editar: rutaEditar,
                                eliminar: (e) => {
                                    eliminar(e, idGarage)
                                }
                            } : {
                                editar: rutaEditar,
                                aprobar_sucursal: (e) => {
                                    aprobar_sucursal(e, idGarage)
                                }
                            })(cell, row)
                        }
                    />
                    <TableHeaderColumn
                        dataField="name"
                        dataSort
                    >
                        SUCURSAL
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="address"
                    >
                        DIRECCIÓN
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataAlign="left"
                        dataField="phone_number"
                        dataSort
                    >
                        TELÉFONO
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="email"
                        dataSort
                    >
                        E-MAIL
                    </TableHeaderColumn>

                </Grid>
            </CardLogo>
        )
    }
}
