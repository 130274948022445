import React, { Component } from 'react'
import Grid from "../../../Utils/Grid";
import { TableHeaderColumn } from "react-bootstrap-table";
import { standardActions } from "../../../Utils/Grid/StandardActions";
import Card from '../../../Utils/Cards/Card'
import { HeaderImportExcel } from '../../../Utils/Header'
import ExcelForm from '../Crear/ExcelForm'
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import LoadMask from 'Utils/LoadMask/LoadMask';

export default class Listar extends Component {
    state = {
        modal_open: false,
    };
    abrirModal = () => {
        this.toggleModal();
    }
    toggleModal = () => {
        if (this.state.modal_open) {
            this.setState({ modal_open: this.state.modal_open });
        }
        this.setState({ modal_open: !this.state.modal_open });
    };
    componentWillMount() {
        const { listar, page } = this.props;
        listar(page);
    }

    render() {
        const { listar, onSortChange, eliminar, registerExcel, cerrarModal } = this.props; //Funciones
        const { data, loader, page } = this.props; //Constantes
        return (
            <React.Fragment>
                <LoadMask loading={loader} blur>
                    <Card
                        titulo="Marcas de vehículo"
                        header={
                            <HeaderImportExcel
                                link="brand/create"
                                textBtn="Crear marca"
                                to2="Subir excel"
                                btn2Click={this.toggleModal}
                                {...this.props}
                            />
                        }
                    >
                        <Grid hover striped data={data} loading={false} onPageChange={listar} onSortChange={onSortChange} page={page}>
                            <TableHeaderColumn
                                dataField="id"
                                dataAlign="center"
                                isKey
                                width='15%'
                                dataFormat={standardActions({ editar: "brand", })}
                            />
                            <TableHeaderColumn
                                dataField="name"
                                dataSort
                            >
                                NOMBRE
                        </TableHeaderColumn>
                        </Grid>
                    </Card>
                    <Modal
                        isOpen={this.state.modal_open}
                        className="mx-lg-auto mx-md-3 mx-2 pt-4"
                    >
                        <ModalHeader className="card p-3 px-4">
                            Importa tu excel
                        </ModalHeader>
                        <ModalBody className="car mt-3">
                            <ExcelForm
                                cerrarModal={this.toggleModal}
                                onSubmit={registerExcel}
                                create={true}
                            />

                        </ModalBody>
                    </Modal>
                </LoadMask>
            </React.Fragment >
        )
    }
}
