import { handleActions } from 'redux-actions';
import { api } from "api";
import moment from 'moment';

const LOADER = 'LOADER_REPORTES';
const DATA = 'DATA_REPORTES';
const DATA_PRINT = 'DATA_PRINT_REPORTES';
const PAGE = 'PAGE_REPORTES';
const ORDERING = 'ORDERING_REPORTES';
const FILTRO_ANIO = 'FILTRO_ANIO';
const FILTRO_MES = 'FILTRO_MES';
const FILTRO_PLAN = 'FILTRO_PLAN';
const FILTRO_PAGO = 'FILTRO_PAGO';

let meses = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];
let scope = new Date();
const fecha_actual = moment(scope)
let numeroMes = parseInt(fecha_actual.month())
let input = meses[numeroMes]

// -----------------------------------
// Pure Actions
// -----------------------------------

const setLoader = loader => ({
    type: LOADER,
    loader,
});

const setData = data => ({
    type: DATA,
    data,
});

const setDataPrint = data_print => ({
    type: DATA_PRINT,
    data_print,
});

const setPage = page => ({
    type: PAGE,
    page,
});

const setOrdering = ordering => ({
    type: ORDERING,
    ordering,
});

// ------------------------------------
// Actions
// ------------------------------------

const listar = (page = 1) => (dispatch, getStore) => {
    const resource = getStore().reportes;
    const lastDayDate = new Date(resource.filtro_anio.id, resource.filtro_mes.id, 0);
    const params = { page };
    let payment_date_after = ''
    let payment_date_before = ''
    const date = new Date()
    const day_last = new Date(date.getFullYear(), date.getMonth()+1, 0).getDate();

    if (resource.filtro_anio == '' | resource.filtro_anio == undefined | resource.filtro_anio == null) {
        payment_date_after = `${date.getFullYear()}-${date.getMonth()+1}-01`;
        payment_date_before =`${date.getFullYear()}-${date.getMonth()+1}-${day_last}`;
        params.payment_date_after = payment_date_after;
        params.payment_date_before = payment_date_before;
    }else{
        if (date.getFullYear() != resource.filtro_anio.id) {
            payment_date_after = `${resource.filtro_anio.id}-01-01`;
            payment_date_before = `${resource.filtro_anio.id}-12-31`;
            params.payment_date_after = payment_date_after;
            params.payment_date_before = payment_date_before;
        }else{
            payment_date_after = `${resource.filtro_anio.id}-01-01`;
            payment_date_before = `${resource.filtro_anio.id}-${date.getMonth()+1}-31`;
            params.payment_date_after = payment_date_after;
            params.payment_date_before = payment_date_before;
        }
    }

    if (resource.filtro_mes) {
        payment_date_after = `${resource.filtro_anio.id}-${resource.filtro_mes.id}-01`;
        payment_date_before = `${resource.filtro_anio.id}-${resource.filtro_mes.id}-${lastDayDate.getDate()}`;
        params.payment_date_after = payment_date_after;
        params.payment_date_before = payment_date_before;
    }
    else {
        params.is_anual = 1111;
    }

    if (resource.filtro_plan) {
        params.garage__plan__id = resource.filtro_plan.id;
    }
    if (resource.filtro_pago) {
        params.garage__active_payment = resource.filtro_pago.id;
    }
    if (resource.ordering)
        params.ordering = resource.ordering;
    if (resource.search)
        params.search = resource.search;
    
    dispatch(setLoader(true));
    dispatch(setData({
        results: [],
        count: 0,
    }));
    api.get('report', params).then((response) => {
        dispatch(setData(response));
        dispatch(setPage(page));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const imprimirReport = () => (dispatch, getStore) => {
    const resource = getStore().reportes;
    let datos = {}
    let payment_date_after = ''
    let payment_date_before = ''
    const lastDayDate = new Date(resource.filtro_anio.id, resource.filtro_mes.id, 0);
    const date = new Date()
    const day_last = new Date(date.getFullYear(), date.getMonth()+1, 0).getDate();

    if (resource.filtro_anio == '' | resource.filtro_anio == undefined) {
        let payment_date_after = `${date.getFullYear()}-${date.getMonth()+1}-01`;
        let payment_date_before =`${date.getFullYear()}-${date.getMonth()+1}-${day_last}`;
        datos.payment_date_after = payment_date_after;
        datos.payment_date_before = payment_date_before;
    }else{
        if (date.getFullYear() != resource.filtro_anio.id) {
            payment_date_after = `${resource.filtro_anio.id}-01-01`;
            payment_date_before = `${resource.filtro_anio.id}-12-31`;
            datos.payment_date_after = payment_date_after;
            datos.payment_date_before = payment_date_before;

        }else{
            payment_date_after = `${resource.filtro_anio.id}-01-01`;
            payment_date_before = `${resource.filtro_anio.id}-${date.getMonth()+1}-31`;
            datos.payment_date_after = payment_date_after;
            datos.payment_date_before = payment_date_before;
        }
    }

    if (resource.filtro_mes) {
        let payment_date_after = `${resource.filtro_anio.id}-${resource.filtro_mes.id}-01`;
        let payment_date_before = `${resource.filtro_anio.id}-${resource.filtro_mes.id}-${lastDayDate.getDate()}`;
        datos.payment_date_after = payment_date_after;
        datos.payment_date_before = payment_date_before;
    }
    else {
        datos.is_anual = 1111;
    }

    if (resource.filtro_plan) {
        datos.garage__plan__id = resource.filtro_plan.id;
    }
    if (resource.filtro_pago) {
        datos.garage__active_payment = resource.filtro_pago.id;
    }
    dispatch(setLoader(true));
    dispatch(setDataPrint({
        results: [],
        count: 0,
    }));
    api.get('report/imprimir_report', datos).then((response) => {
        dispatch(setDataPrint(response));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const descargarReporte = () => (dispatch, getStore) => {
    const resource = getStore().reportes;
    let params = `/api/report_excel/?auth_token=${localStorage.getItem("token")}`;

    const lastDayDate = new Date(resource.filtro_anio.id, resource.filtro_mes.id, 0);

    const date = new Date()
    const day_last = new Date(date.getFullYear(), date.getMonth()+1, 0).getDate();

    if (resource.filtro_anio == '' | resource.filtro_anio == undefined) {
        let payment_date_after = `${date.getFullYear()}-${date.getMonth()+1}-01`;
        let payment_date_before =`${date.getFullYear()}-${date.getMonth()+1}-${day_last}`;
        params += `&payment_date_after=${payment_date_after}&payment_date_before=${payment_date_before}`;
    }else{
        if (date.getFullYear() != resource.filtro_anio.id) {
            let payment_date_after = `${resource.filtro_anio.id}-01-01`;
            let payment_date_before = `${resource.filtro_anio.id}-12-31`;
            params += `&payment_date_after=${payment_date_after}&payment_date_before=${payment_date_before}`;
        }else{
            let payment_date_after = `${resource.filtro_anio.id}-01-01`;
            let payment_date_before = `${resource.filtro_anio.id}-${date.getMonth()+1}-31`;
            params += `&payment_date_after=${payment_date_after}&payment_date_before=${payment_date_before}`;
        }
            
    }

    if (resource.filtro_mes) {
        let payment_date_after = `${resource.filtro_anio.id}-${resource.filtro_mes.id}-01`;
        let payment_date_before = `${resource.filtro_anio.id}-${resource.filtro_mes.id}-${lastDayDate.getDate()}`;
        params += `&payment_date_after=${payment_date_after}&payment_date_before=${payment_date_before}`;
    }
    else {
        console.log(params);
        params += `&is_anual=1111`;
        console.log(params);
    }
    if (resource.filtro_plan) {
        params += `&garage__plan__id=${resource.filtro_plan.id}`;
    }
    if (resource.filtro_pago) {
        params += `&garage__active_payment=${resource.filtro_pago.id}`;
    }
    window.location.replace(params);
};


const onSortChange = ordering => (dispatch, getStore) => {
    const sort = getStore().talleres.ordering;
    if (ordering === sort) {
        dispatch(setOrdering(`-${ordering}`));
    } else {
        dispatch(setOrdering(ordering));
    }
    dispatch(listar());
};

const filtroAnioChange = filtro_anio => (dispatch) => {
    dispatch({ type: FILTRO_ANIO, filtro_anio })
    dispatch(listar())
};

const filtroMesChange = filtro_mes => (dispatch) => {
    dispatch({ type: FILTRO_MES, filtro_mes })
    dispatch(listar())

};

const filtroPlanChange = filtro_plan => (dispatch) => {
    dispatch({ type: FILTRO_PLAN, filtro_plan })
    dispatch(listar())
};

const filtroPagoChange = filtro_pago => (dispatch) => {
    dispatch({ type: FILTRO_PAGO, filtro_pago })
    dispatch(listar())
};

export const actions = {
    listar,
    descargarReporte,
    onSortChange,
    filtroAnioChange,
    filtroMesChange,
    filtroPlanChange,
    filtroPagoChange,
    imprimirReport,
};

// ------------------------------------
// Reducers
// ------------------------------------

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [DATA]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [DATA_PRINT]: (state, { data_print }) => {
        return {
            ...state,
            data_print,
        };
    },
    [PAGE]: (state, { page }) => {
        return {
            ...state,
            page,
        };
    },
    [ORDERING]: (state, { ordering }) => {
        return {
            ...state,
            ordering,
        };
    },
    [FILTRO_ANIO]: (state, { filtro_anio }) => {
        return {
            ...state,
            filtro_anio,
        };
    },
    [FILTRO_MES]: (state, { filtro_mes }) => {
        return {
            ...state,
            filtro_mes,
        };
    },
    [FILTRO_PLAN]: (state, { filtro_plan }) => {
        return {
            ...state,
            filtro_plan,
        };
    },
    [FILTRO_PAGO]: (state, { filtro_pago }) => {
        return {
            ...state,
            filtro_pago,
        };
    },
};

export const initialState = {
    loader: false,
    filtro_pago: '',
    filtro_plan: '',
    // filtro_anio: "",
    // filtro_mes: "",
    filtro_mes: {
        id: fecha_actual.month() + 1,
        label: input ,
    },
    filtro_anio: {
        id: fecha_actual.year(),
        label: fecha_actual.year(),
    },
    data: {
        results: [],
        count: 0,
    },
    data_print: {
        results: [],
        count: 0,
    },
    page: 1,
    ordering: '',
};

export default handleActions(reducers, initialState);
