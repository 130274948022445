import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { NotificationContainer } from 'react-notifications';

import { Login, Profile, ForgotPassword, ChangePassword, Suscripcion } from './common/components/LoginRegister';
import Demo from './common/components/Demo/Demo';
import ProtectedRoute from './ProtectedRoute';
import Examples from './common/components/Examples/Basic';
import NotFound from './common/components/layout/NotFound/NotFound';

import '../assets/fonts/fonts.css';

require('../../node_modules/font-awesome/css/font-awesome.css');
require('../../node_modules/bootstrap/dist/css/bootstrap.css');
import 'bootstrap/dist/css/bootstrap.min.css';
import Grids from './common/components/Examples/Grids';
import Notificaciones from './common/components/Examples/Notificaciones';
import ExampleTabs from './common/components/Examples/Tabs/Tabs';

import { RolesListar, RolCrear } from './common/components/App/Roles'
import { UsuariosListar, UsuarioCrear } from './common/components/App/Usuarios'
import { TalleresListar, TallerDetalle } from './common/components/App/Talleres';
import { PlanesListar, PlanCrear } from './common/components/App/Planes';
import { SucursalesListar, SucursalCrear } from './common/components/App/Sucursales';
import { PagosListar, PagoCrear } from './common/components/App/Pagos';
import { ReportesListar, ReporteImprimir } from './common/components/App/Reportes';
import { Dashboard } from './common/components/App/Dashboard';
import { MarcaListar, MarcaCrear } from './common/components/App/Marcas';
import { TipoCarListar, TipoCarCrear } from './common/components/App/Tipos';
import { ROLE_MANAGEMENT, MANAGEMENT_SHOPS_BRANCHES, REPORTS, USER_MANAGEMENT, SUBSCRIPTION_MANAGEMENT, PAYMENT_MANAGEMENT } from './utility/constants';

require('../style/index.css');

module.exports = (
    <div>
        <div className="container__content">
            <Switch>
                <Route exact path="/login" component={Login} />
                <Route exact path="/forgot-password" component={ForgotPassword} />
                <Route exact path="/forgot-password/:email" component={ForgotPassword} />
                <Route exact path="/change-password/:token" component={ChangePassword} />

                <ProtectedRoute exact path="/demo" component={Demo} />
                <ProtectedRoute exact path="/" component={Dashboard} />

                <ProtectedRoute exact path="/roles" component={RolesListar} permission={[ROLE_MANAGEMENT]} />
                <ProtectedRoute exact path="/role/create" component={RolCrear} permission={[ROLE_MANAGEMENT]} />
                <ProtectedRoute exact path="/role/:id/edit" component={RolCrear} permission={[ROLE_MANAGEMENT]} />

                <ProtectedRoute exact path="/users" component={UsuariosListar} permission={[USER_MANAGEMENT]} />
                <ProtectedRoute exact path="/user/create" component={UsuarioCrear} permission={[USER_MANAGEMENT]} />
                <ProtectedRoute exact path="/user/:id/edit" component={UsuarioCrear} permission={[USER_MANAGEMENT]} />

                <ProtectedRoute exact path="/plans" component={PlanesListar} permission={[SUBSCRIPTION_MANAGEMENT]} />
                <ProtectedRoute exact path="/plan/create" component={PlanCrear} permission={[SUBSCRIPTION_MANAGEMENT]} />
                <ProtectedRoute exact path="/plan/:id/edit" component={PlanCrear} permission={[SUBSCRIPTION_MANAGEMENT]} />

                <ProtectedRoute exact path="/garages" component={TalleresListar} permission={[MANAGEMENT_SHOPS_BRANCHES, PAYMENT_MANAGEMENT]} />
                <ProtectedRoute exact path="/garage/:id/edit" component={TallerDetalle} permission={[MANAGEMENT_SHOPS_BRANCHES, PAYMENT_MANAGEMENT]} />

                <ProtectedRoute exact path="/garage/:idGarage/branches" component={SucursalesListar} permission={[MANAGEMENT_SHOPS_BRANCHES]} />
                <ProtectedRoute exact path="/garage/:idGarage/branch/create" component={SucursalCrear} permission={[MANAGEMENT_SHOPS_BRANCHES]} />
                <ProtectedRoute exact path="/garage/:idGarage/branch/:id/edit" component={SucursalCrear} permission={[MANAGEMENT_SHOPS_BRANCHES]} />

                <ProtectedRoute exact path="/garage/:idGarage/payments" component={PagosListar} permission={[PAYMENT_MANAGEMENT]} />
                <ProtectedRoute exact path="/garage/:idGarage/payment/create" component={PagoCrear} permission={[PAYMENT_MANAGEMENT]} />

                <ProtectedRoute exact path="/report" component={ReportesListar} permission={REPORTS} />
                <ProtectedRoute exact path="/report_print" component={ReporteImprimir} permission={REPORTS} />

                <ProtectedRoute exact path="/brands" component={MarcaListar} permission={[SUBSCRIPTION_MANAGEMENT]} />
                <ProtectedRoute exact path="/brand/create" component={MarcaCrear} permission={[SUBSCRIPTION_MANAGEMENT]} />
                <ProtectedRoute exact path="/brand/:id/edit" component={MarcaCrear} permission={[SUBSCRIPTION_MANAGEMENT]} />

                <ProtectedRoute exact path="/car_types" component={TipoCarListar} permission={[SUBSCRIPTION_MANAGEMENT]} />
                <ProtectedRoute exact path="/car_type/create" component={TipoCarCrear} permission={[SUBSCRIPTION_MANAGEMENT]} />
                <ProtectedRoute exact path="/car_type/:id/edit" component={TipoCarCrear} permission={[SUBSCRIPTION_MANAGEMENT]} />

                <ProtectedRoute exact path="/page2" component={Examples} />
                <ProtectedRoute exact path="/user-profile" component={Profile} />
                <ProtectedRoute exact path="/grids" component={Grids} />
                <ProtectedRoute exact path="/notifications" component={Notificaciones} />
                <ProtectedRoute exact path="/tabs" component={ExampleTabs} />
                <Route component={NotFound} />
            </Switch>
        </div>
        <NotificationContainer />
    </div>
);
