import { object } from 'prop-types';
import React, { Component } from 'react'
import CardForm from 'Utils/Cards/CardForm';
import LoadMask from 'Utils/LoadMask/LoadMask';
import { array } from 'validate-redux-form';
import Formulario from './Formulario';

export default class Crear extends Component {
    state = {
        editar: false,
    };

    componentWillMount() {
        const { id = 0, idGarage = 0 } = this.props.match.params
        const { leer, leer_taller, datos_taller } = this.props;
        if (id) {
            leer(id, idGarage);
            this.setState({ editar: true });
        }
        if (Object.keys(datos_taller.item).length === 0) {
            leer_taller(idGarage)
        }
    }


    update = (_data) => {
        let data = JSON.parse(JSON.stringify(_data));
        data.garage = this.props.match.params.idGarage
        this.props.editar(this.props.match.params.id, data)
    };
    create = (_data) => {
        let data = JSON.parse(JSON.stringify(_data));
        data.garage = this.props.match.params.idGarage
        this.props.crear(data);
    };

    render() {
        //Value
        const { loader, item, datos_taller } = this.props;
        const { editar } = this.state
        const rutaEditar = `/garage/${this.props.match.params.idGarage}/branches`

        return (
            <CardForm titulo={editar ? "EDITAR SUCURSAL" : "NUEVA SUCURSAL"}>
                <LoadMask loading={loader} blur>
                    {
                        editar ?
                            <Formulario
                                onSubmit={this.update}
                                editando
                                backUrl={rutaEditar}
                                nombre_taller={datos_taller.item.name}
                            />
                            :
                            <Formulario
                                onSubmit={this.create}
                                backUrl={rutaEditar}
                                nombre_taller={datos_taller.item.name}
                            />
                    }
                </LoadMask>
            </CardForm>
        )
    }
}
