import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './styleGrid.css';
import Swal from 'sweetalert2';

import svgDelete from '../../../../../assets/img/icons_table/delete.svg'
import svgEdit from '../../../../../assets/img/icons_table/edit.svg'
import svgSee from '../../../../../assets/img/icons_table/see.svg'
import svgSucursal from '../../../../../assets/img/icons_table/store.svg'
import svgPagos from '../../../../../assets/img/icons_table/money.svg'

import { DELETE_REGISTERS, MANAGEMENT_SHOPS_BRANCHES, PAYMENT_MANAGEMENT } from '../../../../utility/constants'
import { connect } from 'react-redux';
const warningSvg = require('assets/img/icons/warning.svg');
import { SWALMOD } from '../../../../utility/constants'
class Acciones2 extends Component {
    constructor(props) {
        super(props);
    }

    eliminar = (id) => {
        return () => {
            SWALMOD.fire({
                title: '¿Eliminar?',
                text: '¡No podrás revertir esta acción!',
                showCancelButton: true,
                confirmButtonText: '¡Sí, eliminar!',
                cancelButtonText: 'No, cancelar',
                reverseButtons: true,
                imageUrl: warningSvg,
                imageAlt: 'Alerta',
                imageWidth: 90,
            }).then((result) => {
                if (result.value) {
                    this.props.eliminar(id);
                }
            });
        };
    };
    aprobar_sucursal = (id) => {
        return () => {
            SWALMOD.fire({
                title: '¿Aprobar sucursal?',
                text: '¡No podrás revertir esta acción!',
                showCancelButton: true,
                confirmButtonText: '¡Sí, aprobar!',
                cancelButtonText: 'No, aprobar',
                reverseButtons: true,
                imageUrl: warningSvg,
                imageAlt: 'Alerta',
                imageWidth: 90,
            }).then((result) => {
                if (result.value) {
                    this.props.aprobar_sucursal(id);
                }
            });
        };
    };

    render() {

        const { id, ver, editar, editarTaller, eliminar, verSucursal, verPago, me, aprobar_sucursal } = this.props;
        return (
            <div className="d-flex justify-content-center">

                {me.permissions.includes(DELETE_REGISTERS) && eliminar !== undefined && (
                    <a
                        data-toggle="tooltip"
                        data-placement="left"
                        title="Eliminar"
                        style={{ cursor: 'pointer' }}
                        onClick={this.eliminar(id)}
                    >
                        <img className="icon_svg" src={svgDelete} style={{ cursor: "pointer" }} />
                    </a>
                )}
                {editar !== undefined && (
                    <Link to={`${editar}/${id}/edit`} data-toggle="tooltip" data-placement="left" title="Editar">
                        <img className="icon_svg" src={svgEdit} style={{ cursor: "pointer" }} />
                    </Link>
                )}
                {ver !== undefined && (
                    <Link to={`${ver}/${id}/`} data-toggle="tooltip" data-placement="left" title="Ver">
                        <img className="icon_svg" src={svgSee} style={{ cursor: "pointer" }} />
                    </Link>
                )}

                {aprobar_sucursal && (
                    <button onClick={this.aprobar_sucursal(id)} className="btn btn-primary btn-sm text-11 bold">Aprobar</button>
                )}
                {/* items con permisos  */}
                {me.permissions.includes(MANAGEMENT_SHOPS_BRANCHES) && editarTaller && (
                    <Link to={`garage/${id}/edit`} data-toggle="tooltip" data-placement="left" title="Editar">
                        <img className="icon_svg" src={svgEdit} style={{ cursor: "pointer" }} />
                    </Link>
                )}
                {me.permissions.includes(MANAGEMENT_SHOPS_BRANCHES) && verSucursal && (
                    <Link to={`garage/${id}/branches`} data-toggle="tooltip" data-placement="left" title="Ver Sucursales">
                        <img className="icon_svg" src={svgSucursal} style={{ cursor: "pointer" }} />
                    </Link>
                )}
                {me.permissions.includes(PAYMENT_MANAGEMENT) && verPago && (
                    <Link to={`garage/${id}/payments`} data-toggle="tooltip" data-placement="left" title="Ver Pagos">
                        <img className="icon_svg" src={svgPagos} style={{ cursor: "pointer" }} />
                    </Link>
                )}

            </div>
        );
    }
}

const ms2p = (state) => {
    return {
        me: state.login.me,
    };
};
const md2p = {};
const Acciones = connect(ms2p, md2p)(Acciones2);

export function standardActions(acciones) {
    return (cell, row) => {
        return <Acciones id={cell} {...acciones} />;
    };
}
