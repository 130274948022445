import React, { Component } from 'react'
import Grid from "../../../Utils/Grid";
import { TableHeaderColumn } from "react-bootstrap-table";
import { standardActions } from "../../../Utils/Grid/StandardActions";
import Card from '../../../Utils/Cards/Card'
import { HeaderBtnSearch } from '../../../Utils/Header'
import { Async } from "react-select";

export default class Listar extends Component {
    componentWillMount() {
        const { listar, page } = this.props;
        listar(page);
    };


    render() {
        const { listar, onSortChange, eliminar } = this.props; //Funciones
        const { data, loader, page } = this.props; //Constantes
        return (
            <Card
                titulo="Tipos de vehículos"
                header={
                    <HeaderBtnSearch
                        link="car_type/create"
                        textBtn="Crear tipo"
                        {...this.props}
                    />
                }
            >
                <Grid hover striped data={data} loading={loader} onPageChange={listar} onSortChange={onSortChange} page={page}>
                    <TableHeaderColumn
                        dataField="id"
                        dataAlign="center"
                        isKey
                        width="150px"
                        dataFormat={standardActions({ editar: "car_type", eliminar })}
                    />
                    <TableHeaderColumn
                        dataField="name"
                        dataSort
                    >
                        TIPO
                    </TableHeaderColumn>
                </Grid>
            </Card>
        )
    }
}
