import React from 'react';
import { Field, reduxForm } from 'redux-form';
import {
    validate,
    validatorFromFunction,
    validators,
    combine,
} from 'validate-redux-form';

import {
    renderField,
    renderFilePicker,
    SelectField,
    renderNumber,
    renderPasswordField,
} from '../../Utils/renderField/renderField';

import { Link } from "react-router-dom";
import { validationAdministrador } from '../../../../utility/validationForm'
import { ButtonSalirGuardar } from '../../Utils/Button'
import { connect } from 'react-redux'


let ProfileForm = (props) => {
    const { handleSubmit, me, setAvatar, avatar } = props;
    return (
        <form onSubmit={handleSubmit}>
            <div className="row card-form">
                <div className="col-md-6 padding-custom mb-3">
                    <label htmlFor="username">Usuario*</label>
                    <Field name="username" component={renderField} type="text" className="form-control" maxLength={validationAdministrador.username.MaxLength} />
                </div>

                <div className="col-md-6 padding-custom mb-3">
                    <label htmlFor="first_name">Nombre*</label>
                    <Field name="first_name" component={renderField} type="text" className="form-control" maxLength={validationAdministrador.first_name.MaxLength} />
                </div>
                <div className="col-md-6 padding-custom mb-3">
                    <label htmlFor="last_name">Apellidos*</label>
                    <Field name="last_name" component={renderField} type="text" className="form-control" maxLength={validationAdministrador.last_name.MaxLength} />
                </div>
                <div className="col-md-6 padding-custom mb-3">
                    <label htmlFor="email">E-mail*</label>
                    <Field name="email" component={renderField} type="text" className="form-control" maxLength={validationAdministrador.email.MaxLength} />
                </div>
                <div className="col-md-6 padding-custom mb-3">
                    <label htmlFor="phone_number">Teléfono*</label>
                    <Field name="phone_number" isNumericString={true} component={renderNumber} type="text" className="form-control" maxLength={validationAdministrador.phone_number.MaxLength} />
                </div>
                <div className="col-md-6 padding-custom mb-3">
                    <label htmlFor="avatar">Avatar</label>
                    <Field className="mx-3" photo={avatar ? avatar : null} setFile={setAvatar} name="avatar" component={renderFilePicker} />
                </div>
                <div className="col-md-6 padding-custom d-flex">
                    <div className="col-md-12 col-sm-12 align-self-center px-0">
                        <Field name="new_password" placeholder="Nueva contraseña" component={renderPasswordField} />
                    </div>
                </div>
                <div className="col-md-6 padding-custom mb-3 d-flex">
                    <p className="text-right azul text-12 bold align-self-end ml-auto mb-0">* Campos requeridos</p>
                </div>
            </div>
            <ButtonSalirGuardar />
        </form>
    );
};

ProfileForm = reduxForm({
    form: 'profile', // a unique identifier for this form
    validate: (data) => {
        let errors = {};
        if (data && data.new_password && data.new_password.length < 6) {
            errors.new_password = "Mínimo 6 carácteres"
        }
        if (data && data.new_password && data.new_password.length > 150) {
            errors.new_password = "Máximo 150 carácteres"
        }
        return {
            ...errors, ...validate(data, {
                username: validationAdministrador.username.Combine,
                first_name: validationAdministrador.first_name.Combine,
                last_name: validationAdministrador.last_name.Combine,
                email: validationAdministrador.email.Combine,
                phone_number: validationAdministrador.phone_number.Combine,
            })
        }
    },

})(ProfileForm);


ProfileForm = connect(state => {
    const form = state.form.profile;
    let avatar = "";
    if (form && form.values) {
        const values = form.values;
        if (values.avatar)
            avatar = values.avatar
    }
    return {
        avatar
    }
})(ProfileForm);


export default ProfileForm;
