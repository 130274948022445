import React, { useState } from 'react';
import { Field, reduxForm } from 'redux-form';
import {
    validate,
} from 'validate-redux-form';

import {
    renderField,
    renderNumber,
} from '../../../Utils/renderField/renderField';
import { change as changeForm } from 'redux-form';
import { validationPlanes } from '../../../../../utility/validationForm'
import { ButtonSalirGuardar } from '../../../Utils/Button'

const PlanForm = (props) => {
    const [Ilimitada, setIlimitada] = useState(false)
    const MAX_VAL = 3;
    const MIN_VAL = 0;
    const withValueCap = (inputObj) => {
        const { formattedValue, floatValue } = inputObj;
        if (floatValue == MIN_VAL || floatValue == null) {
            return formattedValue === ""
        } else {
            return (floatValue <= MAX_VAL && floatValue > MIN_VAL)
        }
    };
    const { handleSubmit, dispatch } = props;
    return (
        <form onSubmit={handleSubmit}>
            <div className="row card-form">

                <div className="col-md-6 padding-custom mb-3 align-self-end">
                    <label htmlFor="name">Nombre*</label>
                    <Field name="name" component={renderField} type="text" className="form-control" maxLength={validationPlanes.name.MaxLength} />
                </div>

                <div className="col-md-6 padding-custom mb-3 align-self-end">
                    <label htmlFor="monthly_fee">Tarifa mensual*</label>
                    <Field name="monthly_fee" decimalScale={2} prefix="$ " component={renderNumber} className="form-control" maxLength={validationPlanes.monthly_fee.MaxLength} />
                </div>
                <div className="col-md-6 padding-custom mb-3 align-self-end">
                    <label htmlFor="annual_fee">Tarifa anual*</label>
                    <Field name="annual_fee" decimalScale={2} prefix="$ " component={renderNumber} className="form-control" maxLength={validationPlanes.annual_fee.MaxLength} />
                </div>
                <div className="col-md-6 padding-custom mb-3 align-self-end">
                    <label htmlFor="amount_per_additional_order">Costo por orden extra*</label>
                    <Field name="amount_per_additional_order" decimalScale={2} prefix="$ " component={renderNumber} className="form-control" maxLength={validationPlanes.annual_fee.MaxLength} />
                </div>
                <div className="col-md-6 padding-custom mb-3 align-self-end">
                    <label htmlFor="work_orders">Límite de ordenes de trabajo*</label>
                    {!Ilimitada ?
                        <Field name="work_orders" component={renderNumber} className="form-control" maxLength={6} />
                        :
                        <label htmlFor="amount_per_additional_order" className="rojo bold d-block">Ordenes ilimitadas</label>
                    }
                </div>
                <div className="col-md-6 padding-custom mb-3 align-self-end">
                    <label htmlFor="enable_type_work_orders">Número de tipos de ordenes activas*</label>
                    <Field name="enable_type_work_orders" isAllowed={withValueCap} component={renderNumber} type="text" className="form-control" isNumericString={true} maxLength={validationPlanes.enable_type_work_orders.MaxLength} />
                </div>

                <div className="col-md-6 padding-custom mb-3 align-self-end">
                    <button type="button" onClick={() => {

                        if (Ilimitada) {
                            dispatch(changeForm('PlanForm', 'work_orders', null))
                        } else {
                            dispatch(changeForm('PlanForm', 'work_orders', 1000000))
                        }
                        setIlimitada(!Ilimitada)
                    }} className="btn btn-secondary mx-1">{!Ilimitada ? "Ordenes ilimitadas" : "Ordenes con límite"}</button>
                </div>
                <div className="col-md-6 padding-custom mb-3 d-flex">
                    <p className="text-right azul text-12 bold align-self-end ml-auto mb-0">* Campos requeridos</p>
                </div>
                <p className="col-12 padding-custom mb-3 mt-3 text-14">- Número de tipos de ordenes activas solo permite rango de 1 a 3.</p>
            </div>
            <ButtonSalirGuardar link="/plans" />
        </form>
    );
};

export default reduxForm({
    form: 'PlanForm',
    validate: (data) => {
        return validate(data, {
            name: validationPlanes.name.Combine,
            monthly_fee: validationPlanes.monthly_fee.Combine,
            annual_fee: validationPlanes.annual_fee.Combine,
            work_orders: validationPlanes.work_orders.Combine,
            enable_type_work_orders: validationPlanes.enable_type_work_orders.Combine,
        })
    },
})(PlanForm);
