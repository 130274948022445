// * * Saber si el dato es una imagen * * * *
export const esImagen = (filename) => {
    const img = ['jpg', 'png', 'gif', 'jpeg'];

    const inicioNombre = filename.substr(0, 30)
    const finalNombre = filename.substr(-20)

    for (let index = 0; index < img.length; index++) {
        if (inicioNombre.includes(img[index]) || finalNombre.includes(img[index]))
            return true
    };
    return false


}
