import React, { Component, Fragment } from 'react'
import { Field, reduxForm } from 'redux-form';
import { renderField, renderNumber, renderFilePicker, SelectField, renderDatePicker } from '../../../Utils/renderField/renderField';
import { ButtonSalirGuardar } from '../../../Utils/Button';
import { validate } from 'validate-redux-form';
import { validationGarage, validationAdministrador } from '../../../../../utility/validationForm'
import { COUNTRIES } from '../../../../../utility/countries';
import { ACTIVE_GARAGE, PENDING_GARAGE } from '../../../../../utility/constants';
import { connect } from 'react-redux'
import { Link } from "react-router-dom";


let TallerForm = (props) => {
    const { handleSubmit, setImg, item, logo } = props;
    const {
        plan = {},
        garage_status = PENDING_GARAGE,
        id: id_garage = 0
    } = item
    const {
        name = ""
    } = plan || {}

    let active_plan = (garage_status == ACTIVE_GARAGE)
    return (
        <Fragment>
            <div className='card-content-form2 px-0 px-0'>
                <div className="col-12 col-lg-9">
                    <form
                        id="TallerForm"
                        onSubmit={handleSubmit}
                    >
                        <div className="row card-form">
                            <div className="col-md-6 padding-custom mb-3 ">
                                <label htmlFor="logo">Logo</label>
                                <Field
                                    name="logo"
                                    photo={logo || null}
                                    component={renderFilePicker}
                                    setFile={setImg}
                                />
                            </div>
                            <div className="col-md-6 padding-custom mb-3 ">
                                {active_plan ?
                                    <span >Plan activo</span>
                                    :
                                    <span >Plan</span>
                                }
                                <h3 className="text-20 bold verde">{name}</h3>
                                {!active_plan &&
                                    <Link
                                        className="btn btn-rojo btn-sm mr-1"
                                        to={`/garage/${id_garage}/payments`}
                                    >
                                        Aprobar plan de suscripción
                                </Link>
                                }
                            </div>
                            <div className="col-md-6 padding-custom mb-3">
                                <label htmlFor="name">Nombre*</label>
                                <Field name="name" component={renderField} type="text" className="form-control" maxLength={validationGarage.name.MaxLength} />
                            </div>
                            <div className="col-md-6 padding-custom mb-3">
                                <label htmlFor="phone_number">Teléfono*</label>
                                <Field name="phone_number" isNumericString={true} component={renderNumber} type="text" className="form-control" maxLength={validationGarage.phone_number.MaxLength} />
                            </div>
                            <div className="col-md-6 padding-custom mb-3">
                                <label htmlFor="email">E-mail*</label>
                                <Field name="email" component={renderField} type="email" className="form-control" maxLength={validationGarage.email.MaxLength} />
                            </div>
                            <div className="col-md-6 padding-custom mb-3">
                                <label htmlFor="country">País*</label>
                                <Field
                                    name="country"
                                    valueKey="id"
                                    labelKey="name"
                                    options={COUNTRIES}
                                    component={SelectField}
                                />
                            </div>
                            <div className="col-md-6 padding-custom mb-3">
                                <label htmlFor="city">Ciudad*</label>
                                <Field name="city" component={renderField} type="text" className="form-control" maxLength={validationGarage.city.MaxLength} />
                            </div>
                            <div className="col-md-6 padding-custom mb-3">
                                <label htmlFor="foundation_date">Fecha fundación</label>
                                <Field name="foundation_date" component={renderDatePicker} className="form-control" />
                            </div>
                            <div className="col-md-6 padding-custom mb-3">
                                <label htmlFor="subdomain">Subdominio*</label>
                                <Field name="subdomain" component={renderField} type="text" className="form-control" maxLength={validationGarage.subdomain.MaxLength} />
                            </div>
                            <div className="col-md-6 padding-custom mb-3">
                                <label htmlFor="prefix">Prefijo*</label>
                                <Field name="prefix" component={renderField} type="text" className="form-control" maxLength={validationGarage.prefix.MaxLength} />
                            </div>
                            <div className="col-md-12 padding-custom mb-3">
                                <label htmlFor="address">Dirección*</label>
                                <Field name="address" component={renderField} type="text" className="form-control" maxLength={validationGarage.address.MaxLength} />
                            </div>
                            <div className="col-12 padding-custom mb-3">
                                <p className="text-right azul text-12 bold">* Campos requeridos</p>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <ButtonSalirGuardar formSubmit="TallerForm" link="/garages" />
        </Fragment>
    );

}

TallerForm = reduxForm({
    form: 'TallerForm', // a unique identifier for this form
    validate: (data) => {
        let errors = {};
        if (data) {
            errors.proprietor = validate(data.proprietor || {}, {
                first_name: validationAdministrador.first_name.Combine,
                last_name: validationAdministrador.last_name.Combine,
                phone_number: validationAdministrador.phone_number.Combine,
            })
        }
        return {
            ...errors, ...validate(data, {
                name: validationGarage.name.Combine,
                email: validationGarage.email.Combine,
                phone_number: validationGarage.phone_number.Combine,
                country: validationGarage.country.Combine,
                city: validationGarage.city.Combine,
                address: validationGarage.address.Combine,
                subdomain: validationGarage.subdomain.Combine,
                prefix: validationGarage.prefix.Combine,
            })
        }
    },
})(TallerForm);




TallerForm = connect(state => {
    const form = state.form.TallerForm;
    let logo = "";
    if (form && form.values) {
        const values = form.values;
        logo = values.logo || ""
    }
    return {
        logo
    }
})(TallerForm);


export default TallerForm;
