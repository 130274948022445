import React, { Component } from 'react'
import Card from '../../../Utils/Cards/Card'
import { HeaderBtnSearch } from '../../../Utils/Header'
import Tabs, { TabPane } from 'rc-tabs';
import TabContent from 'rc-tabs/lib/TabContent';
import ScrollableInkTabBar from 'rc-tabs/lib/ScrollableInkTabBar';
import Listar from './Listar'
import { ACTIVE_GARAGE, INACTIVE_GARAGE, PENDING_GARAGE } from '../../../../../utility/constants'

export default class TabsListar extends Component {
    render() {
        return (
            <Card
                titulo="Talleres"
                subTitulo="Suscripciones"
                header={
                    <HeaderBtnSearch
                        {...this.props}
                    />
                }
            >
                <Tabs
                    defaultActiveKey={String(ACTIVE_GARAGE)}
                    tabBarPosition="top"
                    onChange={(tab) => this.props.estadoTallerTab(tab)}
                    renderTabBar={() => <ScrollableInkTabBar />}
                    renderTabContent={() => <TabContent />}
                    activeKey={String(this.props.estado_taller_tab)}
                >
                    <TabPane
                        tab="ACTIVOS"
                        key={ACTIVE_GARAGE}
                    >
                        <Listar {...this.props} />
                    </TabPane>
                    <TabPane
                        tab="PENDIENTES"
                        key={PENDING_GARAGE}
                    >
                        <Listar {...this.props} deactivate_branch />
                    </TabPane>
                    <TabPane
                        tab="INACTIVOS"
                        key={INACTIVE_GARAGE}
                    >
                        <Listar {...this.props} />
                    </TabPane>
                </Tabs>
            </Card>
        )
    }
}
