import { combineReducers } from 'redux';
import { routerReducer as routing } from 'react-router-redux';
import { reducer as formReducer } from 'redux-form';
import login from './modules/cuenta/login';
import profile from './modules/cuenta/profile';
import usuarios from './modules/usuarios';
import notificaciones from './modules/notificaciones/notificaciones';
import roles from './modules/roles'
import talleres from './modules/talleres'
import planes from './modules/planes';
import sucursales from './modules/sucursales';
import pagos from './modules/pagos';
import reportes from './modules/reportes';
import dashboard from './modules/dashboard';
import marcas from './modules/marcas';
import tipos from './modules/tipos';

export default combineReducers({
    form: formReducer,
    login,
    profile,
    usuarios,
    routing,
    notificaciones,
    roles,
    planes,
    talleres,
    sucursales,
    pagos,
    reportes,
    dashboard,
    marcas,
    tipos,
});
