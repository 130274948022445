import React, { Fragment } from 'react';
import { Field, reduxForm } from 'redux-form';
import { validate, validators, combine } from 'validate-redux-form';
import { renderField } from 'Utils/renderField';
import { Link } from "react-router-dom";
import { validationEmail } from '../../../../utility/validationForm'


const ForgotPasswordForm = (props) => {
    const { handleSubmit } = props;
    return (

        <form
            name="loginForm"
            className="form-validate mb-lg text-center"
            onSubmit={handleSubmit}
        >
            <h6 className="text-bold mb-4 mt-n3">¿OLVIDASTE TU CONTRASEÑA?</h6>

            <label className="text-bold">Recuperar</label>
            <div className="form-group has-feedback">
                <label className="bold" htmlFor="email">Ingresa tu correo electrónico</label>
                <Field
                    name="email"
                    label="email"
                    component={renderField}
                    type="text"
                    className="form-control"
                    maxLength={validationEmail.email.MaxLength}
                />
            </div>
            <div className="buttons-box mt-4">
                <button
                    type="submit"
                    className="btn btn-primary text-18 btn-block m-1 align-self-center"
                >
                    ENVIAR
                </button>
            </div>
            <div className="buttons-box mt-3">
                <Link
                    className="btn btn-gris text-18 btn-block mr-1"
                    to="/login"
                >
                    Regresar
                </Link>
            </div>
        </form>
    );
};

export default reduxForm({
    form: 'ForgotPasswordForm',
    validate: (data) => {
        return validate(data, {
            email: validationEmail.email.Combine,
        })
    },
})(ForgotPasswordForm);
