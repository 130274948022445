import React, { Component, Fragment } from 'react'
import CardForm from 'Utils/Cards/CardForm';
import LoadMask from 'Utils/LoadMask/LoadMask';
import Formulario from './Formulario';
import Modal from "../../../Utils/Modal/ReactModal";
import { ButtonSalirGuardar, ButtonSalir } from '../../../Utils/Button';
import { RenderDollar, RenderDate } from '../../../Utils/renderField/renderReadField';
export default class Crear extends Component {
    state = {
        editar: false,
        modal_open: false,
        data_payment: {},
    };

    componentWillMount() {
        const { id = 0, idGarage = 0 } = this.props.match.params
        const { leer, leer_taller, leer_payment, datos_taller } = this.props;
        if (id) {
            leer(id, idGarage);
            this.setState({ editar: true });
        }
        leer_payment(idGarage)
    }
    actualizar = (data) => {
        this.props.editar(this.props.match.params.id, data)
    };

    send_data = (data) => {
        this.setState({ modal_open: true, data_payment: data })
    };
    close_modal = () => {
        this.setState({ modal_open: false })
    };
    render() {
        //Function
        const { crear } = this.props;
        //Value
        const { loader, item, datos_taller } = this.props;
        const { editar } = this.state
        const rutaEditar = `/garage/${this.props.match.params.idGarage}/payments`

        const {
            amount_pay = 0,
            pending_amount = 0,
            id: id_garage = 0,
        } = datos_taller.item || {}

        return (
            <CardForm titulo={editar ? "EDITAR PAGO" : "AGREGAR PAGO"}>
                <LoadMask loading={loader} blur>
                    <Modal
                        show_modal={this.state.modal_open}
                        showModal={this.close_modal}
                    >
                        <div className="row card-form">
                            <div className="col-12 padding-custom mb-4">
                                <span className="text-18 bold d-block" style={{ borderBottom: "2px #CD0A0A solid", paddingBottom: 5 }}>Confirmar datos</span>
                            </div>
                            <div className="col-md-12 padding-custom mb-3">
                                <span className="d-block">Fecha de pago</span>
                                <RenderDate value={this.state.data_payment.payment_date} className="text-18 bold negro d-block" />
                            </div>
                            <div className="col-md-12 padding-custom mb-3">
                                <span className="d-block">Monto</span>
                                <RenderDollar value={this.state.data_payment.amount} className="text-18 bold negro d-block" />
                            </div>
                            <div className="col-md-12 padding-custom mb-3">
                                <span className="d-block"># Referencia</span>
                                <span className="text-18 bold negro d-block">{this.state.data_payment.reference}</span>
                            </div>
                        </div>
                        <ButtonSalirGuardar btnText1="No, Guardar" link={this.close_modal}
                            btnText2="Si, Guardar"
                            link2={() => {
                                this.close_modal()
                                crear(this.state.data_payment)
                            }}
                        />
                    </Modal>
                    {
                        id_garage ?
                            editar ?
                                <Formulario
                                    onSubmit={this.actualizar}
                                    item={item}
                                    backUrl={rutaEditar}
                                    data_garage={datos_taller.item}
                                    amount_pay={amount_pay}
                                    id_garage={id_garage}
                                />
                                :
                                <Formulario
                                    onSubmit={this.send_data}
                                    modal_open={this.state.modal_open}
                                    close_modal={this.close_modal}

                                    backUrl={rutaEditar}
                                    data_garage={datos_taller.item}
                                    amount_pay={amount_pay + pending_amount}
                                    amount_pay_only={amount_pay}
                                    id_garage={id_garage}
                                />
                            :
                            <Fragment></Fragment>

                    }
                </LoadMask>
            </CardForm>
        )
    }
}
