import React from 'react';
import { Field, reduxForm, FieldArray } from 'redux-form';
import {
    validate,
} from 'validate-redux-form';

import {
    renderField,
    renderNumber,
} from '../../../Utils/renderField/renderField';
const svgDelete = require('assets/img/icons_table/delete.svg');

import { validationBrand, validationModel } from '../../../../../utility/validationForm'
import { ButtonSalirGuardar } from '../../../Utils/Button'

const renderComponentes = ({ fields, meta: { error, submitFailed }, }) => {
    if (!fields.length) {
        fields.push({});
    }
    return (
        <div className="col-12 form-group m-auto px-0 px-sm-0 pt-2">
            <div className="my-n2 d-none d-md-block">
                <div className="react-bs-container-header" style={{ height: 25 }} >
                    <div className="d-none d-md-flex row py-1 px-0 px-sm-1 px-lg-1 px-xl-1">
                        <div className="col-1 mb-2 d-none d-md-flex justify-content-center align-items-center">
                        </div>
                        <div className="col-md-3 mb-2 px-1">
                            <label htmlFor="name" className="ml-2 blanco d-block">NOMBRE*</label>
                        </div>
                    </div>
                </div>
            </div>

            {fields.map((item, index) => {
                return (

                    <div className="d-flex py-3 px-0 px-sm-1 flex-md-row flex-column" key={index}>
                        <div className="order-1 order-md-0 px-1 d-inline d-md-flex">
                            {fields.length > 1 &&
                                <img src={svgDelete} alt="Eliminar"
                                    className="icon_svg pointer my-auto row mx-auto"
                                    onClick={() => {
                                        fields.remove(index)
                                    }}
                                />
                            }
                            <span className="text-18 bold d-block  d-md-none d-none mx-2 mt-2" style={{ borderBottom: "2px #7EA04D solid", paddingBottom: 5 }} />
                        </div>
                        <div className="flex-1 px-0 pb-2 pb-md-0">
                            <label htmlFor="name" className="gris-oscuro d-block d-md-none">Nombre*</label>
                            <Field
                                name={`${item}.name`}
                                type="text"
                                component={renderField}
                                maxLength={validationModel.name.MaxLength}
                            />
                        </div>
                    </div>
                )
            })}
            <div className="d-flex justify-content-center mt-3">
                <button type="button" className="btn btn-secondary py-1" onClick={() => {
                    fields.push({})
                }}>
                    AGREGAR
                </button>
            </div>
        </div>
    )
}

const MarcaForm = (props) => {
    const { handleSubmit } = props;
    return (
        <form onSubmit={handleSubmit}>
            <div className="row card-form">
                <div className="col-12 padding-custom mb-3 ">
                    <label htmlFor="name">Nombre*</label>
                    <Field name="name" component={renderField} type="text" className="form-control" maxLength={validationModel.name.MaxLength} />
                </div>
                <div className="col-12 padding-custom mb-3 mb-0">
                    <p className="text-right azul text-12 bold mb-0">* Campos requeridos</p>
                </div>
                <div className="col-12 padding-custom mb-0 mb-md-4 mt-4">
                    <span className="text-18 bold d-block" style={{ borderBottom: "2px #CD0A0A solid", paddingBottom: 5 }}>
                        MODELOS DE LA MARCA DE VEHICULO
                    </span>
                </div>
                <div className="col-12 padding-custom mb-3">
                    <FieldArray name="models" component={renderComponentes} />
                </div>

            </div>
            <ButtonSalirGuardar link="/brands" />
        </form>
    );
};

export default reduxForm({
    form: 'MarcaForm',
    validate: (data) => {
        let errors = {};
        let DetalleArrayErrors = [];
        if (data && data.models && data.models.length) {
            data.models.forEach((detalle, detalleIndex) => {
                DetalleArrayErrors[detalleIndex] = validate(detalle, {
                    name: validationBrand.name.Combine,
                })
            });
        }
        errors.models = DetalleArrayErrors
        return {
            ...errors, ...validate(data, {
                name: validationModel.name.Combine,

            })
        }
    },
})(MarcaForm);
