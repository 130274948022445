import React, { Fragment } from 'react';

function CardSearch(props) {
    const { subTitulo } = props
    return (
        <Fragment>
            <div className="mb-1 mb-md-2">
                <h1 className="text-45 medium d-inline my-0">{props.titulo} </h1>
                {subTitulo &&
                    <span className="text-20 medium my-0">{subTitulo}</span>
                }
            </div>
            {props.header}
            <div className="card-small p-2 p-md-4 pb-4 card-empty">
                <div className='px-0 px-0'>
                    {props.children}
                </div>
            </div>
        </Fragment>
    )
}

export default CardSearch;
