import React from 'react';
import { Field, reduxForm } from 'redux-form';
import {
    validate,
} from 'validate-redux-form';

import {
    renderField,
} from '../../../Utils/renderField/renderField';

import { validationsOthers } from '../../../../../utility/validationForm'
import { ButtonSalirGuardar } from '../../../Utils/Button'


let TipoForm = (props) => {
    const { handleSubmit } = props;
    return (
        <form onSubmit={handleSubmit}>
            <div className="row card-form">

                <div className="col-md-6 padding-custom mb-3 align-self-end">
                    <label htmlFor="name">Nombre*</label>
                    <Field name="name" component={renderField} type="text" className="form-control" maxLength={validationsOthers.numeroLetro50.MaxLength} />
                </div>
                <div className="col-12 padding-custom mb-3">
                    <p className="azul text-12 bold">* Campos requeridos</p>
                </div>
            </div>
            <ButtonSalirGuardar link="/car_types" />
        </form>
    );
};

export default reduxForm({
    form: 'TipoForm',
    validate: (data) => validate(data, {
        name: validationsOthers.numeroLetro50.Combine,
    })


})(TipoForm);
