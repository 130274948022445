import { connect } from 'react-redux';
import { actions } from "../../../../../redux/modules/reportes";
import ReportImp from './ReportImp';

const ms2p = (state) => {
    const profile = state.login.me;
    return {
        ...state.reportes,
        profile,
    };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(ReportImp);